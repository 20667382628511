import {
	BrowserRouter as Router,
} from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
import Counter from "./Counter";
import Feed from "./Feed";

function App() {
  return (
	<Router>

		<Header />

		<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
		  <div className="max-w-3xl mx-auto">

			<div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 mb-4 mt-4">
			  <div className="px-4 py-5 sm:p-6">

	  			<div className="mb-4 flex justify-center">
					<Counter />
	  			</div>

	  			<div className="mt-4">
					<Feed />
				</div>

			  </div>
			</div>

		  </div>
		</div>

		<Footer />

	</Router>
  );
}

export default App;
