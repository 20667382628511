import { CountdownCircleTimer } from "react-countdown-circle-timer";

const renderTime = ({ remainingTime }) => {

	if (remainingTime === 0 ) {
		return <div>Time is up!</div>;
	};

	return (
		<div>{ remainingTime }</div>
	);

};

const Counter = () => {
	var count = 0;	
	var time = 10;
	var rest = 60000;

	return (

		<CountdownCircleTimer
         	onComplete={() => {
            // increment counter
            count = count + 1;
            if (count < 3) {
              return [true, rest];
            };
            return [false,rest];
          	}}
          	isPlaying
          	duration={time}
          	colors={[["#4c51bf", 0.33], ["#5a67d8", 0.33], ["#667eea"]]}
        >
		{ renderTime }
		</ CountdownCircleTimer>
	);

};

export default Counter;
