import { Fragment } from "react";

const Feed = () => {

	return (

		<Fragment>

			<div className="flow-root">
			  <ul className="-mb-8">
				<li>
				  <div className="relative pb-8">
					<span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
					<div className="relative flex space-x-3">
					  <div>
						<span className="h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white">
						  <svg className="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
							<path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
						  </svg>
						</span>
					  </div>
					  <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
						<div>
						  <p className="text-sm text-gray-500">Get ready to train</p>
						</div>
						<div className="text-right text-sm whitespace-nowrap text-gray-500">
						  <time datetime="2020-09-20">30 sec</time>
						</div>
					  </div>
					</div>
				  </div>
				</li>

				<li>
				  <div className="relative pb-8">
					<span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
					<div className="relative flex space-x-3">
					  <div>
						<span className="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center ring-8 ring-white">
						  <svg className="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
							<path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
						  </svg>
						</span>
					  </div>
					  <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
						<div>
						  <p className="text-sm text-gray-500">Top rung: 25 mm</p>
						</div>
						<div className="text-right text-sm whitespace-nowrap text-gray-500">
						  <time datetime="2020-09-22">10 sec</time>
						</div>
					  </div>
					</div>
				  </div>
				</li>

				<li>
				  <div className="relative pb-8">
					<span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
					<div className="relative flex space-x-3">
					  <div>
						<span className="h-8 w-8 rounded-full bg-green-500 flex items-center justify-center ring-8 ring-white">
						  <svg className="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
							<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
						  </svg>
						</span>
					  </div>
					  <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
						<div>
						  <p className="text-sm text-gray-500">Rest</p>
						</div>
						<div className="text-right text-sm whitespace-nowrap text-gray-500">
						  <time datetime="2020-09-28">60 sec</time>
						</div>
					  </div>
					</div>
				  </div>
				</li>

				<li>
				  <div className="relative pb-8">
					<span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
					<div className="relative flex space-x-3">
					  <div>
						<span className="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center ring-8 ring-white">
						  <svg className="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
							<path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
						  </svg>
						</span>
					  </div>
					  <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
						<div>
						  <p className="text-sm text-gray-500">Top rung: 25 mm</p>
						</div>
						<div className="text-right text-sm whitespace-nowrap text-gray-500">
						  <time datetime="2020-09-30">10 sec</time>
						</div>
					  </div>
					</div>
				  </div>
				</li>

				<li>
				  <div className="relative pb-8">
					<div className="relative flex space-x-3">
					  <div>
						<span className="h-8 w-8 rounded-full bg-green-500 flex items-center justify-center ring-8 ring-white">
						  <svg className="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
							<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
						  </svg>
						</span>
					  </div>
					  <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
						<div>
						  <p className="text-sm text-gray-500">Rest</p>
						</div>
						<div className="text-right text-sm whitespace-nowrap text-gray-500">
						  <time datetime="2020-10-04">60 sec</time>
						</div>
					  </div>
					</div>
				  </div>
				</li>
			  </ul>
			</div>

		</Fragment>
	);
};

export default Feed;
